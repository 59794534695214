:root {
    --truweel-green: #6fa09a;
    --truweel-yellow: #fcbe38;

    --truweel-green-50: #e6f1f1;
    --truweel-green-100: #c0dddb;
    --truweel-green-200: #9cc7c4;
    --truweel-green-300: #7eb1ac;
    --truweel-green-400: #6fa09a;
    --truweel-green-500: #688f89;
    --truweel-green-600: #61827d;
    --truweel-green-700: #59726e;
    --truweel-green-800: #53625f;
    --truweel-green-900: #434645;
    --truweel-green-A100: #cafff8;
    --truweel-green-A200: #97fff1;
    --truweel-green-A400: #64ffea;
    --truweel-green-A700: #4bffe7;

    --truweel-yellow-50 : #fff6e3;
    --truweel-yellow-100 : #fee8b9;
    --truweel-yellow-200 : #fed98a;
    --truweel-yellow-300 : #fdc95b;
    --truweel-yellow-400 : #fcbe38;
    --truweel-yellow-500 : #fcb215;
    --truweel-yellow-600 : #fcab12;
    --truweel-yellow-700 : #fba20f;
    --truweel-yellow-800 : #fb990c;
    --truweel-yellow-900 : #fa8a06;
    --truweel-yellow-A100 : #ffffff;
    --truweel-yellow-A200 : #fff6ee;
    --truweel-yellow-A400 : #ffddbb;
    --truweel-yellow-A700 : #ffd0a2;
}

.loge-bg-green {
    background-color: var(--truweel-green);
}

.loge-bg-yellow {
    background-color: var(--truweel-yellow);
}
