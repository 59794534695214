.p-element.w-25 {
    width: 25%;
}
.p-element.w-50 {
    width: 50%;
}
.p-element.w-75 {
    width: 75%;
}

p-dropdown.p-element .p-dropdown.p-component {
    width: inherit;
}
p-dropdown.p-element.w-100 > .p-dropdown.p-component {
    width: 100%;
}

.p-element.w-100 > .p-component,
.p-element.w-25 > .p-component,
.p-element.w-50 > .p-component,
.p-element.w-75 > .p-component {
    width: 100%;
}

div.w-100, textarea.w-100 {
    width: 100%;
}

.p-component, .p-inputtext {
    font-size: 9pt;
}

p-fieldset.m-0 > fieldset, p-button.m-0 {
    margin: 0;
}
p-fieldset.m-1 > fieldset, p-button.m-1 {
    margin: 0.25rem;
}
p-fieldset.m-2 > fieldset, p-button.m-2 {
    margin: 0.5rem;
}
p-fieldset.m-3 > fieldset, p-button.m-3 {
    margin: 1rem;
}
p-fieldset.m-4 > fieldset, p-button.m-4 {
    margin: 1.5rem;
}
p-fieldset.m-5 > fieldset, p-button.m-5 {
    margin: 3rem;
}

.mb-0 {
    margin-bottom: 0;
}
.mb-1 {
    margin-bottom: 0.25rem;
}
.mb-2 {
    margin-bottom: 0.5rem;
}
.mb-3 {
    margin-bottom: 1rem;
}
.mb-4 {
    margin-bottom: 1.5rem;
}
.mb-5 {
    margin-bottom: 3rem;
}

.mt-0 {
    margin-top: 0;
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-3 {
    margin-top: 1rem;
}
.mt-4 {
    margin-top: 1.5rem;
}
.mt-5 {
    margin-top: 3rem;
}

.font-bold {
    font-weight: 700 !important;
}

.block {
    display: block !important;
}