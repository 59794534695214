/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

@font-face {
  font-family: 'century_gothic_paneuropeanBd';
  src: url('styles/fonts/centurygothicpaneuropeanbold-webfont.woff2') format('woff2'),
    url('styles/fonts/centurygothicpaneuropeanbold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pill_gothic_600mgthin';
  src: url('styles/fonts/pill_gothic_600mg_thin-webfont.woff2') format('woff2'),
    url('styles/fonts/pill_gothic_600mg_thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

::ng-deep button ul li a {
  text-decoration: unset !important;
}
