
.font-primary {
    color: #3B82F6;
}

.font-secondary {
    color: #64748b;
}

.font-success {
    color: #22c55e;
}

.font-info {
    color: #0ea5e9;
}

.font-warning {
    color: #f97316;
}

.font-help {
    color: #a855f7;
}

.font-danger, .font-error {
    color: #ef4444;
}

.font-contrast {
    color: 020617;
}
